<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-card class="elevation-x py-3">
    <v-card-title class="pt-0 font-alt">Questionnaires traités</v-card-title>
    <v-card-subtitle class="font-weight-light text-caption font-alt">Liste des questionnaires acquittés ou archivés</v-card-subtitle>
    <surveys-list acquit @select-item="selectSurveyItem" />
    <survey-detail v-if="showDetailDialog"
                   :survey-status="surveyStatus"
                   :survey-response="surveyResponse"
                   :survey-comment="surveyComment"
                   :survey-acknowledgment-date="surveyAcknowledgmentDate"
                   :survey-acknowledgment-author-iri="surveyAcknowledgmentAuthorIri"
                   :patient-selected="patientSelected"
                   :stay-survey-iri="staySurveyIri"
                   :stay-survey-id="staySurveyId"
                   :end-date="endDate"
                   @close="closeDetailDialog"
    />
  </v-card>
</template>

<script>
  import SurveysList from '@/modules/professionals/components/survey/SurveysList'
  import SurveyDetail from '@/modules/surveys/components/responses/SurveyDetailsDialog'
  import * as surveys from '@/modules/surveys/api'

  export default {
    name: 'SurveysAcquitted',
    components: {SurveysList, SurveyDetail},
    data() {
      return {
        surveyResponse: [],
        patientSelected: [],
        surveyComment: {},
        surveyAcknowledgmentDate: null,
        surveyAcknowledgmentAuthorIri: null,
        surveyStatus: {},
        endDate: null,
        staySurveyId: {},
        staySurveyIri: {},
        showDetailDialog: false
      }
    },
    methods: {
      async selectSurveyItem(item) {
        if (item.surveyResponseId){
          this.patientSelected = item.stay.patientIdentity
          this.surveyComment = item.surveyResponseAcknowledgmentComment
          this.surveyAcknowledgmentDate = item.surveyResponseAcknowledgmentDate
          this.surveyAcknowledgmentAuthorIri = item.surveyResponseAcknowledgmentAuthor
          this.surveyStatus = item.surveyResponseStatus
          this.endDate = item.endDate
          this.staySurveyIri = item['@id']
          this.staySurveyId = item.id
          try {
            this.surveyResponse = (await surveys.surveyResponses.get(item.surveyResponseId)).data
          } catch (error) {
            this.$root.$emit('error', error)
          }
          this.showDetailDialog = true
        }

      },
      closeDetailDialog() {
        this.showDetailDialog = false
      }
    }
  }
</script>
